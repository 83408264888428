<template>
  <div class="content mb-5 mx-auto">
    <p class="main-title bold">WEBSITE TERMS AND CONDITIONS OF USE</p>
    <p class="title mt-4 bold"><span class="number-order">1.</span> <b>About the Website</b></p>
    <ol class="letter-parenthesis-list">
      <li>
        Welcome to <a href="https://sell.autoflip.com.au" target="_blank">www.sell.autoflip.com.au</a> (the 'Website'). The Website is used to
        outline the services or products that we may provide (the 'Services').
      </li>
      <li>
        The Website is operated by AutoFlip Pty Ltd ABN 87 638 825 751
        ('AutoFlip'). Access to and use of the Website, or any of its associated
        Products or Services, is provided by AutoFlip. Please read these terms
        and conditions (the 'Terms') carefully. By using, browsing and/or
        reading the Website or providing information through the Website this
        signifies that you have read, understood and agree to be bound by the
        Terms. If you do not agree with the Terms, you must cease usage of the
        Website, or any of Services, immediately.
      </li>
      <li>
        AutoFlip reserves the right to review and change any of the Terms by
        updating this page at its sole discretion. When AutoFlip updates the
        Terms, it will use reasonable endeavours to provide you with notice of
        updates to the Terms. Any changes to the Terms take immediate effect
        from the date of their publication. Before you continue, we recommend
        you keep a copy of the Terms for your records.
      </li>
    </ol>
    <p class="title mt-4 bold">
      <span class="number-order">2.</span> Acceptance of the Terms
    </p>
    <p>
      You accept the Terms by remaining on the Website. You may also accept the
      Terms by clicking to accept or agree to the Terms where this option is
      made available to you by AutoFlip in the user interface.
    </p>
    <p class="title mt-4 bold">
      <span class="number-order">3.</span> Registration to use the Services
    </p>
    <ol class="letter-parenthesis-list">
      <li>
        In order to access the Services through the Website, you may need to
        register and provide certain information through the Website (the
        'Account').
      </li>
      <li>
        As part of the registration process, or as part of your continued use of
        the Services, you may be required to provide personal information about
        yourself and
      </li>
      <li>
        You warrant that any information you give to AutoFlip in the course of
        completing the registration process will be accurate, correct and up to
        date.
      </li>
      <li>
        You may not use the Website, the Services and may not accept the Terms
        if:
        <ol class="roman-num-parenthesis-list">
          <li>
            you are not of legal age to form a binding contract with AutoFlip;
            or
          </li>
          <li>
            you are a person barred from receiving the Services under the laws
            of Australia or other countries including the country in which you
            are resident or from which you use the Services.
          </li>
        </ol>
      </li>
    </ol>
    <p class="title mt-4 bold">
      <span class="number-order">4.</span> Your obligations as a User
    </p>
    <ol class="letter-parenthesis-list">
      <li>
        You agree to comply with the following:
        <ol class="roman-num-parenthesis-list">
          <li>
            you will use the Services only for purposes that are permitted by:
            <ol class="letter-upper-parenthesis-list">
              <li>the Terms; and</li>
              <li>
                any applicable law, regulation or generally accepted practices
                or guidelines in the relevant jurisdictions;
              </li>
            </ol>
          </li>
          <li>
            you have the sole responsibility for protecting the confidentiality
            of any password and/or email address. Use of your password by any
            other person may result in the immediate cancellation of the
            Services;
          </li>
          <li>
            any use of your registration information by any other person, or
            third parties, is strictly prohibited. You agree to immediately
            notify AutoFlip of any unauthorised use of your password or email
            address or any breach of security of which you have become aware;
          </li>
          <li>
            access and use of the Website is limited, non-transferable and
            allows for the sole use of the Website only by you for the purposes
            of AutoFlip providing the Services;
          </li>
          <li>
            you will not use the Services or the Website in connection with any
            commercial endeavours except those that are specifically endorsed or
            approved by the management of AutoFlip;
          </li>
          <li>
            you will not use the Services or Website for any illegal and/or
            unauthorised use which includes collecting email addresses of
            Members by electronic or other means for the purpose of sending
            unsolicited email or unauthorised framing of or linking to the
            Website;
          </li>
          <li>
            you agree that commercial advertisements, affiliate links, and other
            forms of solicitation may be removed from the Website without notice
            and may result in termination of the Services. Appropriate legal
            action will be taken by AutoFlip for any illegal or unauthorised use
            of the Website; and
          </li>
          <li>
            you acknowledge and agree that any automated use of the Website or
            its Services is prohibited.
          </li>
        </ol>
      </li>
    </ol>
    <p class="title mt-4 bold"><span class="number-order">5.</span> Payment</p>
    <ol class="letter-parenthesis-list">
      <li>
        Where the option is given to you, you may make payment for Services (the Services Fee') by way of electronic funds transfer into our nominated
        bank account or through payment platforms that we nominate from time to
        time
      </li>
      <li>
        In making any payment through a payment platform in relation to your use
        of the Services, you warrant that you have read, understood and agree to
        be bound by the payment platform's terms and conditions which are
        available on their website.
      </li>
      <li>
        You acknowledge and agree that where a request for the payment of the
        Services Fee is returned or denied, for whatever reason, by your
        financial institution or is unpaid by you for any other reason, then you
        are liable for any costs, including banking fees and charges, associated
        with the Services Fee.
      </li>
      <li>
        You agree and acknowledge that AutoFlip can vary Services Fee at any
        time.
      </li>
    </ol>
    <p class="title mt-4 bold"><span class="number-order">6.</span> Refund Policy</p>
    <p>
      AutoFlip will only provide you with a refund of the Services Fee in the
      event they are unable to continue to provide the Services or if the
      manager of AutoFlip makes a decision, at its absolute discretion, that it
      is reasonable to do so under the circumstances.
    </p>
    <p class="title mt-4 bold">
      <span class="number-order">7.</span> Copyright and Intellectual Property
    </p>
    <ol class="letter-parenthesis-list">
      <li>
        The Website, the Services and all of the related products of AutoFlip
        are subject to copyright. The material on the Website is protected by
        copyright under the laws of Australia and through international
        treaties. Unless otherwise indicated, all rights (including copyright)
        in the Services and compilation of the Website (including but not
        limited to text, graphics, logos, button icons, video images, audio
        clips, Website, code, scripts, design elements and interactive features)
        or the Services are owned or controlled for these purposes, and are
        reserved by AutoFlip or its contributors.
      </li>
      <li>
        All trademarks, service marks and trade names are owned, registered
        and/or licensed by AutoFlip, who grants to you a worldwide,
        non-exclusive, royalty-free, revocable license whilst you are a user of
        the Website to:
        <ol class="roman-num-parenthesis-list">
          <li>use the Website pursuant to the Terms;</li>
          <li>
            copy and store the Website and the material contained in the Website
            in your device's cache memory; and
          </li>
          <li>
            print pages from the Website for your own personal and
            non-commercial use.
          </li>
          <li>
          AutoFlip does not grant you any other rights whatsoever in relation to
          the Website or the Services. All other rights are expressly reserved by
          AutoFlip.
            </li>
          </ol>
        </li>
      <li>
        AutoFlip retains all rights, title and interest in and to the Website
        and all related Services. Nothing you do on or in relation to the
        Website will transfer any:
        <ol class="roman-num-parenthesis-list">
          <li>
            business name, trading name, domain name, trade mark, industrial
            design, patent, registered design or copyright, or
          </li>
          <li>
            a right to use or exploit a business name, trading name, domain
            name, trade mark or industrial design, or
          </li>
          <li>
            a thing, system or process that is the subject of a patent,
            registered design or copyright (or an adaptation or modification of
            such a thing, system or process),
          </li>
        </ol>
      </li>
      <li>
        Data, valuations, price guidance or any other information on this Website is for personal and non-commercial use only. You may not, without the prior written permission of AutoFlip and the permission of any other relevant rights owners (including without limitation, any artists published on the Website or any providers of data to AutoFlip):
        <ol class="roman-num-parenthesis-list">
          <li>
            modify, copy, distribute, transmit, display, perform, reproduce, publish or licence any data and valuations from this Website;
          </li>
          <li>
            use or attempt to use any data and valuations published on this Website to create any web site or publication;
          </li>
          <li>
            mirror or frame any data and valuations published within this Website;
          </li>
          <li>
            use any automated process of any sort to query, access or copy any data and valuations on this Website or generate or compile any document or database based on the data and valuations published on this Website;
          </li>
          <li>
            transfer or sell any data and valuations offered on this Website
          </li>
          <li>
            broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services or any other content on the Website for any purpose, unless otherwise provided by these Terms.
          </li>
        </ol>
      </li>
    </ol>
    <p class="title mt-4 bold"><span class="number-order">8.</span> Privacy</p>
    <p>
      AutoFlip takes your privacy seriously and any information provided through
      your use of the Website and/or Services are subject to AutoFlip's Privacy
      Policy, which is available on the Website.
    </p>
    <p class="title mt-4 bold">
      <span class="number-order">9.</span> General Disclaimer and Acknowledgement
    </p>
    <ol class="letter-parenthesis-list">
      <li>
        Nothing in the Terms limits or excludes any guarantees, warranties,
        representations or conditions implied or imposed by law, including the
        Australian Consumer Law (or any liability under them) which by law may
        not be limited or excluded.
      </li>
      <li>
        Subject to this clause, and to the extent permitted by law:
        <ol class="roman-num-parenthesis-list">
          <li>
            all terms, guarantees, warranties, representations or conditions
            which are not expressly stated in the Terms are excluded; and
          </li>
          <li>
            AutoFlip will not be liable for any special, indirect or
            consequential loss or damage (unless such loss or damage is
            reasonably foreseeable resulting from our failure to meet an
            applicable Consumer Guarantee), loss of profit or opportunity, or
            damage to goodwill arising out of or in connection with the Services
            or these Terms (including as a result of not being able to use the
            Services or the late supply of the Services), whether at common law,
            under contract, tort (including negligence), in equity, pursuant to
            statute or otherwise.
          </li>
        </ol>
      </li>
      <li>
        Use of the Website and the Services is at your own risk. Everything on
        the Website and the Services is provided to you "as is" and "as
        available" without warranty or condition of any kind. None of the
        affiliates, directors, officers, employees, agents, contributors and
        licensors of AutoFlip Pty Ltd make any express or implied representation
        or warranty about the Services or any products or Services (including
        the products or Services of AutoFlip) referred to on the Website,
        includes (but is not restricted to) loss or damage you might suffer as a
        result of any of the following:
        <ol class="roman-num-parenthesis-list">
          <li>
            failure of performance, error, omission, interruption, deletion,
            defect, failure to correct defects, delay in operation or
            transmission, computer virus or other harmful component, loss of
            data, communication line failure, unlawful third party conduct, or
            theft, destruction, alteration or unauthorised access to records;
          </li>
          <li>
            the accuracy, suitability or currency of any information on the
            Website, the Services, or any of its Services related products
            (including third party material and advertisements on the Website);
          </li>
          <li>
            costs incurred as a result of you using the Website, the Services or
            any of the products of AutoFlip; and
          </li>
          <li>
            the Services or operation in respect to links which are provided for
            your convenience.
          </li>
        </ol>
      </li>
      <li>
        AutoFlip may place advertisements in or around the Site you acknowledge that AutoFlip may receive a commission if a user of the Website clicks an advertisement or purchases goods or services from the advertiser.
      </li>
      <li>
        AutoFlip uses data from Automotive Data Services Pty Ltd ('RedBook') to produce vehicle data and valuations. Data form RedBook is gathered from a variety of sources and while all due care is taken in producing the data and valuations, neither RedBook nor AutoFlip can guarantee or make any representations regarding the use of, or reliance, on such data neither RedBook or AutoFlip not responsible for all the information provided to it and you acknowledge that you understand that you should not rely on the data or valuations without making your own independent assessment of the vehicle and other sources of information. Neither RedBook or AutoFlip are liable for any loss or damages (other than in respect of any liability which may not lawfully be excluded) relating to your use of, or reliance on, this valuation and data.
      </li>
    </ol>
    <p class="title mt-4 bold">
      <span class="number-order">10.</span> Limitation of liability
    </p>
    <ol class="letter-parenthesis-list p-2-char-li">
      <li>
        AutoFlip's total liability arising out of or in connection with the
        Services or these Terms, however arising, including under contract, tort
        (including negligence), in equity, under statute or otherwise, will not
        exceed the resupply of the Services to you.
      </li>
      <li>
        You expressly understand and agree that AutoFlip, its affiliates,
        employees, agents, contributors and licensors shall not be liable to you
        for any direct, indirect, incidental, special consequential or exemplary
        damages which may be incurred by you, however caused and under any
        theory of liability. This shall include, but is not limited to, any loss
        of profit (whether incurred directly or indirectly), any loss of
        goodwill or business reputation and any other intangible loss.
      </li>
    </ol>
    <p class="title mt-4 bold">
      <span class="number-order">11.</span> Termination of Contract
    </p>
    <ol class="letter-parenthesis-list p-2-char-li">
      <li>
        The Terms will continue to apply until terminated by either you or by
        AutoFlip as set out below.
      </li>
      <li>
        If you want to terminate the Terms, you may do so by closing your
        accounts for all of the Services which you use and making any
        outstanding payments.
      </li>
      <li>
        AutoFlip may at any time, terminate the Terms with you if:
        <ol class="roman-num-parenthesis-list">
          <li>
            you have breached any provision of the Terms or intend to breach any
            provision;
          </li>
          <li>AutoFlip is required to do so by law;</li>
          <li>
            the provision of the Services to you by AutoFlip is, in the opinion
            of AutoFlip, no longer commercially viable; or
          </li>
          <li>otherwise at AutoFlip's discretion.</li>
        </ol>
      </li>
      <li>
        Subject to local applicable laws, AutoFlip reserves the right to
        discontinue or cancel and may suspend or deny, in its sole discretion,
        your access to all or any portion of the Website or the Services without
        notice if you breach any provision of the Terms or any applicable law or
        if your conduct impacts AutoFlip name or reputation or violates the
        rights of those of another party.
      </li>
    </ol>
    <p class="title mt-4 bold"><span class="number-order">12.</span> Indemnity</p>
    <p class="p-2-char-title">
        You agree to indemnify AutoFlip, its affiliates, employees, agents,
        contributors, third party content providers and licensors from and
        against:
        <ol>
          <li>
            all actions, suits, claims, demands, liabilities, costs, expenses,
            loss and damage (including legal fees on a full indemnity basis)
            incurred, suffered or arising out of or in connection with your
            content to upload to or enter into the Website;
          </li>
          <li>
            any direct or indirect consequences of you accessing, using or
            transacting on the Website or attempts to do so; and/or
          </li>
          <li>any breach of the Terms.</li>
        </ol>
      </p>
    <p class="title mt-4 bold">
      <span class="number-order">13.</span> Venue and Jurisdiction
    </p>
    <p class="p-2-char-title">In the event of any dispute arising out of or in relation to the
        Website, you agree that the exclusive venue for resolving any dispute
        shall be in the courts of Victoria, Australia.</p>
    <p class="title mt-4 bold"><span class="number-order">14.</span> Governing Law</p>
    <p class="p-2-char-title">The Terms are governed by the laws of Victoria, Australia. Any dispute,
        controversy, proceeding or claim of whatever nature arising out of or in
        any way relating to the Terms and the rights created hereby shall be
        governed, interpreted and construed by, under and pursuant to the laws
        of Victoria, Australia, without reference to conflict of law principles,
        notwithstanding mandatory rules. The validity of this governing law
        clause is not contested. The Terms shall be binding to the benefit of
        the parties hereto and their successors and assigns.</p>
    <p class="title mt-4 bold">
      <span class="number-order">15.</span> Independent Legal Advice
    </p>
    <p class="p-2-char-title">Both parties confirm and declare that the provisions of the Terms are
        fair and reasonable and both parties having had the opportunity to
        obtain independent legal advice if they desire and declare the Terms are
        not against public policy on the grounds of inequality or bargaining
        power or general grounds of restraint of trade.</p>
    <p class="title mt-4 bold"><span class="number-order">16.</span> Severance</p>
    <p class="p-2-char-title">If any part of these Terms is found to be void or unenforceable by a
        court of competent jurisdiction, that part shall be severed and the rest
        of the Terms shall remain in force.</p>
  </div>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style lang="scss">
.content {
  color: #404040;
  max-width: 394px;
  padding: 35px 20px 63px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 20px;

  @media screen and (min-width: 768px) {
    max-width: 1080px;
    padding: 45px 50px 63px;
  }

  p {
    padding-left: 25px;
    @media screen and (min-width: 768px) {
      padding-left: 40px;
    }
  }

  p,
  li {
    font-size: 14px;
    font-family: Avenir Next LT W05 Thin;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  .main-title {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .bold {
    font-family: Avenir Next LT W05 Bold;
  }

  .number-order {
    padding-right: 5px;

    @media screen and (min-width: 768px) {
      padding-right: 15px;
    }
  }

  // ======== Parenthesis lists
  ol.letter-parenthesis-list {
    padding-left: 55px;
    &.p-2-char-li {
      // Padding left for section that begin with two digit title
      padding-left: 65px;

      @media screen and (min-width: 768px) {
        padding-left: 95px;
      }
    }
    @media screen and (min-width: 768px) {
      padding-left: 80px;
    }
  }
  ol.roman-num-parenthesis-list {
    padding-left: 30px;
  }

  ol.letter-parenthesis-list,
  ol.roman-num-parenthesis-list,
  ol.letter-upper-parenthesis-list {
    counter-reset: list;
    margin: 0;
  }
  ol.roman-num-parenthesis-list,
  ol.letter-upper-parenthesis-list {
    margin-top: 10px;
  }

  ol.letter-parenthesis-list > li,
  ol.roman-num-parenthesis-list > li,
  ol.letter-upper-parenthesis-list > li {
    list-style: none;
    position: relative;
  }

  ol.letter-parenthesis-list > li:before,
  ol.roman-num-parenthesis-list > li:before,
  ol.letter-upper-parenthesis-list > li:before {
    counter-increment: list;
    position: absolute;
    left: -2em;
  }

  ol.letter-parenthesis-list > li:before {
    content: "(" counter(list, lower-alpha) ") ";
  }
  ol.roman-num-parenthesis-list > li:before {
    content: "(" counter(list, lower-roman) ") ";
  }
  ol.letter-upper-parenthesis-list > li:before {
    content: "(" counter(list, upper-alpha) ") ";
  }

  ol.letter-parenthesis-list > li:not(:last-child),
  ol.roman-num-parenthesis-list > li:not(:last-child),
  ol.letter-upper-parenthesis-list > li:not(:last-child) {
    padding-bottom: 10px;
  }

  .p-2-char-title {
    // Padding left for section that begin with two digit title
    padding-left: 40px;
  }
}
</style>
